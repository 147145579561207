// 邓亚鑫-V2.2.1
<template>
  <div class="parent" ref="parent">
    <WrapModal
      :tooSmall="true"
      :width="396"
      :visible="remoteVisible"
      :maskClosable="true"
      :closable="false"
      @ok="submit"
      @cancel="cancel"
      :getContainer="() => $refs.parent"
    >
      <a-row class="confirm-modal">
        <a-col style="width:100%">
          <section @click="clickFocus" style="margin-bottom: 24px;padding-left:16px">
            <a-input
              allowClear
              class="search-input"
              style="width: 235px"
              v-model:value="search"
              placeholder="请输入姓名查询"
              ref="inputbox"
            >
              <template #prefix> <img src="@/assets/svg/search.svg" /> </template>
            </a-input>
            <a-button type="primary" class="btn search-btn" @click="searchApprovalList">查询</a-button>
          </section>
          <div style="overflow-y: auto;max-height:386px;padding-left: 16px;" class="show-scrollbar">
            <StaffList :type="1" :list="list" @checkRemote="checkRemote" />
          </div>
          <!-- <div style="line-height: 200px; text-align: center" v-show="!check.searchLbwList.length">暂无审批人</div> -->
        </a-col>
      </a-row>
    </WrapModal>
  </div>
</template>

<script>
import { cmsMessage } from '@/utils/utils'
import { defineComponent, reactive, toRefs, ref, onMounted, computed, watch, onUnmounted, nextTick } from 'vue'
import WrapModal from '@/components/WrapModal/index'
import StaffList from '@/views/businessManage/process/components/staffList'
import { getStaffRemoteList } from '@/apis/businessManage'
export default defineComponent({
  components: {
    WrapModal,
    StaffList
  },
  props: {
    remoteVisible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const inputbox = ref(null)
    const { emit } = ctx
    const state = reactive({
      search: '',
      list: [],
      totalList: [],
      checkStaff: {}
    })
    const submit = () => {
      if (!state.checkStaff.id) {
        cmsMessage('error', '请选择确认人')
        return
      }
      emit('submitModel', state.checkStaff)
      emit('update:remoteVisible', false)
      state.checkStaff = {}
      state.search = ''
    }
    const checkRemote = value => {
      state.checkStaff = value
    }
    const cancel = () => {
      state.checkStaff = {}
      state.search = ''
      emit('update:remoteVisible', false)
    }
    const getProcessStaff = async () => {
      // 获取自由流程的审批人
      const res = await getStaffRemoteList()
      state.list = res.data.map(item => ({ ...item, label: item.name, value: item.id, check: false }))
      state.totalList = res.data.map(item => ({ ...item, label: item.name, value: item.id, check: false }))
    }
    const searchApprovalList = () => {
      state.list = []
      state.totalList.forEach(item => {
        if (item.label.includes(state.search)) {
          state.list.push(item)
        }
      })
    }
    // 搜索框点击
    const clickFocus = () => {
      inputbox.value.focus()
    }
    // onMounted(() => {
    //   getProcessStaff()
    // })
    watch(
      () => props.remoteVisible,
      newValue => {
        if (newValue) {
          getProcessStaff()
        }
      }
    )
    return {
      cancel,
      submit,
      searchApprovalList,
      ...toRefs(state),
      checkRemote,
      clickFocus,
      inputbox
    }
  }
})
</script>

<style lang="scss" scoped>
.confirm-modal {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 16px 0 0;
  box-sizing: content-box;
  .search-btn {
    margin-left: 14px;
  }
  :deep(.ant-list-empty-text) {
    transform: translateY(0) !important;
  }
}
</style>
